import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import cloudinaryOptimize from "../helpers/cloudinaryOptimize"
import { Image } from "theme-ui"
import useStories from "../hooks/useStories"
import cloudinaryString from "../helpers/cloudinaryString"
import TextContent from "../components/TextContent"

export default function TourTheVenue({ data }) {
  const { getStoryByTag } = useStories()

  const venuePageHero = getStoryByTag("tour-the-venue-pagehero")
  const venue1 = getStoryByTag("tour-the-venue-1")
  const venue2 = getStoryByTag("tour-the-venue-2")
  const venue3 = getStoryByTag("tour-the-venue-3")
  const venue4 = getStoryByTag("tour-the-venue-4")
  const venue5 = getStoryByTag("tour-the-venue-5")
  const venue6 = getStoryByTag("tour-the-venue-6")
  const venue7 = getStoryByTag("tour-the-venue-7")
  const venue8 = getStoryByTag("tour-the-venue-8")
  const venue9 = getStoryByTag("tour-the-venue-9")

  const stories = [
    venue6,
    venue7,
    venue8,
    venue4,

    venue9,
    venue2,

    venue3,

    venue5,
  ]

  const renderPartyRooms = () =>
    stories.map((story, idx) => (
      <Content className="columns is-vcentered" id={idx}>
        <ColumnText className="column">
          <div>
            {story?.title ? <ContentTitle>{story?.title}</ContentTitle> : null}
            {story?.subtitle !== 0 ? (
              <ContentSubtitle>{story?.subtitle}</ContentSubtitle>
            ) : null}
            {story?.body ? (
              <ContentText>
                <div dangerouslySetInnerHTML={{ __html: story?.body }}></div>
              </ContentText>
            ) : null}
            {story?.ctaLinks?.length ? (
              <a
                href={story?.ctaLinks[0]?.url}
                className="button"
                style={{ marginTop: "1rem" }}
              >
                {story?.ctaLinks[0]?.title}
              </a>
            ) : null}
          </div>
        </ColumnText>
        <ColumnImage className="column">
          <ContentImageContainer>
            <Image
              src={cloudinaryString(story?.media?.[0]?.cloudinaryId, 1500)}
            />
          </ContentImageContainer>
        </ColumnImage>
      </Content>
    ))

  return (
    <Layout
      pageTitle={venuePageHero.title}
      subHeading={venuePageHero.subtitle}
      pageHero={cloudinaryOptimize(
        "https://res.cloudinary.com/gonation/image/upload/w_1800,q_auto,f_auto/v1722961274/sites/dockside-brewing/tour_1_1.jpg",
        1200
      )}
    >
      <ContentContainer isBlack>
        <MainContent>
          <TextContent
            title={venue1?.title}
            subtitle={venue1?.subtitle}
            body={venue1?.body}
          />
        </MainContent>
      </ContentContainer>
      <ContentContainer className="">{renderPartyRooms()}</ContentContainer>
      {/* <BookAPartyCTAContainer>
        <Button>
          <Link to="/book-a-party">Book a party</Link>
        </Button>
      </BookAPartyCTAContainer> */}
    </Layout>
  )
}

const BookAPartyCTAContainer = styled.div`
  text-align: center;
  background: ${({ theme }) => theme.secondary};
  padding: 2rem 1.5rem;
`

const Button = styled.button`
  color: ${({ theme }) => theme.primary};
  background: transparent;
  box-shadow: none;
  border: none;
  border: 2px solid white;
  padding: 0.5rem 2rem;
  font-weight: bold;
  font-size: 1.25rem;

  a {
    color: white;
  }
`

const Page = styled.section`
  background-color: ${props =>
    props.theme.light ? props.theme.light : "grey"};
  padding: 2rem 1rem;
`

const MainContent = styled.div`
  padding: 3rem 0rem;
  max-width: 767px;
  margin: 0 auto 2rem;

  @media (min-width: 767px) {
    text-align: center;
  }
`

const PrivateEventsPackages = styled.div`
  margin-top: 3rem;
  max-width: 767px;
  margin: 0 auto 2rem;
  @media (min-width: 767px) {
    text-align: center;
  }
`

const CTABoxes = styled.div`
  display: flex;
  height: 200px;
  justify-content: space-between;
  justify-content: center;
  max-width: 767px;
  margin: 2rem auto;
`

const CTABox = styled.a`
  display: flex;
  margin: 0.25rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 50%;
  height: 200px;
`

const CTATitle = styled.h2`
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 1rem;
  text-align: center;
  color: white;
  @media (min-width: 767px) {
    font-size: 1.75rem;
  }
`

const CTAImage = styled.img`
  height: 100%;
  transition: all ease-in-out 0.5s;
  width: 100%;
  filter: brightness(0.5);
`

const PageTitle = styled.h1`
  font-size: 1.75rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
`

const PageDescription = styled.p`
  font-weight: 400;
  margin-bottom: 1.5rem;
`

const SectionTitle = styled.h2`
  margin: 2rem 1rem;
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
`

const FormContainer = styled.div`
  /* max-width: 767px; */
  padding: 2rem 1.5rem;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('${cloudinaryOptimize(
    "https://res.cloudinary.com/gonation/image/upload/v1596205445/sites/dockside-brewing/contact-bg.jpg",
    1200
  )}');
  background-position: center;
  background-size: cover;
  /* margin: 0 auto 2rem; */
  @media (min-width: 767px) {
    text-align: center;
  }
`

const Title = styled.h3`
  color: ${props => (props.theme.primary ? props.theme.primary : "black")};
  margin-bottom: 3rem;
  text-align: center;
  font-size: 1.75rem;
  font-weight: bold;
  text-transform: uppercase;
`

const ContentContainer = styled.div`
  ${({ isBlack, theme }) =>
    isBlack
      ? `background: ${theme.secondary}; color: #fff; border-bottom: 2px solid #fff`
      : ""};
  ${({ isBlack }) => (isBlack ? "> div {margin-bottom: 0}" : "")};
`

// renderPartyRooms Styles

const ColumnImage = styled.div`
  padding: 0;
`
const ColumnText = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    height: auto;
  }
  padding: 0;
  > * {
    padding: 2rem 1.5rem;
  }
  @media (min-width: 768px) {
    > * {
      padding: 0 5.5rem;
    }
  }
  p {
    font-weight: 400;
  }
`
const Content = styled.div`
  margin: 0 !important;
  &:nth-of-type(odd) ${ColumnImage} {
    order: -1;
  }
  ${({ id, theme }) => (id === 0 ? `background: ${theme.primary}` : "")};
  ${({ id, theme }) => (id === 1 ? `background: ${theme.secondary}` : "")};
  ${({ id, theme }) => (id === 2 ? `background: ${theme.light}` : "")};
  ${({ id, theme }) => (id === 3 ? `background: ${theme.primary}` : "")};
  ${({ id, theme }) => (id === 4 ? `background: ${theme.secondary}` : "")};
  ${({ id, theme }) => (id === 5 ? `background: ${theme.light}` : "")};
  ${({ id, theme }) => (id === 6 ? `background: ${theme.primary}` : "")};

  * {
    ${({ id, theme }) =>
      id === 1 || id === 4 || id === 0 || id === 3
        ? `color: white!important`
        : ""};
  }
`

const ContentImageContainer = styled.div`
  display: flex;
`

const ContentImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 500px;
`

const ContentTitle = styled.h2`
  font-size: 2.25rem;
  font-weight: bold;
  text-transform: uppercase;
  color: inherit;
  margin-bottom: 0.5rem;
`

const ContentSubtitle = styled.h4`
  color: #111;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 1.5rem;
  word-break: break-word;
`

const ContentText = styled.p`
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-right: 8rem;
  }
`

const ContentLabel = styled.span`
  color: ${props => (props.theme.secondary ? props.theme.secondary : "black")};
  font-weight: bold;
  margin-right: 3px;
`
